<template>
    <div class="flex flex-col justify-center sm:px-6 lg:px-8 ccss--user-transaction" v-if="transaction">
    <div class="mx-auto w-full max-w-xl">
        <h2 class="text-center text-3xl font-extrabold">
            {{ $t('usertransaction.title') }} {{ transaction.attributes.transactionnumber }}
        </h2>
    </div>
    <div class="mt-8 mx-auto w-full max-w-xl">
        <div class="bg-white py-8 px-4 shadow sm:rounded-sm sm:px-10">


                <div v-if="transaction.attributes.mode == 'quickpickup'"
                     class="highlighted-box radius-anchor">
                    <div class="">
                        {{ $t('confirmation.pickup.description') }}
                    </div>
                    <router-link
                        :to="{ name: 'transaction.quickpickup', params: {location_slug: location_slug, transaction_id: transaction.id}}"
                        @click="orderAgainRedirect()"
                        class="inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                        {{ $t('confirmation.pickup.button') }}
                    </router-link>
                </div>
                <!-- <div v-if="transaction.attributes.mode == 'qrcode'"> -->
                <div>
                    <div class="flex justify-center">
                        <vue-qrcode :value="transaction.id" />
                    </div>
                    <div class="flex justify-center">
                        {{ transaction.attributes.shortcode }}
                    </div>
                </div>

                <div class="confirmation-locker-list">
                    <div class="border radius-anchor" v-for="(ticket, index) in transaction.relationships.tickets"
                         v-bind:key="index">
                        <ticket :ticket="ticket" />
                    </div>
                </div>
                <div class="py-5">

                    <dl v-if="(['delivery', 'takeaway']).includes(this.transaction.attributes.method)" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.method_' + transaction.attributes.method) }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                <span class="font-medium">{{ transaction.attributes.method_time }}</span> - {{ transaction.attributes.method_date }}
                            </dd>
                        </div>
                    </dl>

                    <dl v-if="transaction.attributes.account_id" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.account') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.relationships.account.attributes.name }}
                            </dd>
                        </div>
                    </dl>

                    <dl class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.transactionnumber') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.transactionnumber }} <span
                                class="">({{ transaction.attributes.shortcode }})</span>
                            </dd>
                        </div>
                    </dl>


                    <dl v-if="transaction.relationships.table" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.table') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.relationships.table.attributes.name }}
                            </dd>
                        </div>
                    </dl>
                    <!-- <dl class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                Status
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.status }}
                            </dd>
                        </div>
                    </dl> -->

                    <dl v-if="transaction.attributes.customer_email" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.email') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.customer_email }}
                            </dd>
                        </div>
                    </dl>

                    <dl v-if="transaction.attributes.customer_phone_number" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.phonenumber') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.customer_phone_number }}
                            </dd>
                        </div>
                    </dl>

                    <dl v-if="transaction.attributes.customer_name" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.name') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.customer_name }}
                            </dd>
                        </div>
                    </dl>

                    <dl v-if="transaction.attributes.customer_streetname" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.address') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                <div
                                    v-if="transaction.attributes.customer_streetname || transaction.attributes.customer_housenumber">
                                    {{ transaction.attributes.customer_streetname }}
                                    {{ transaction.attributes.customer_housenumber }}
                                </div>
                                <div
                                    v-if="transaction.attributes.customer_zipcode || transaction.attributes.customer_city">
                                    {{ transaction.attributes.customer_zipcode }}
                                    {{ transaction.attributes.customer_city }}
                                </div>
                            </dd>
                        </div>
                    </dl>

                    <dl class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.ordered_at') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.timestamp }}
                            </dd>
                        </div>
                    </dl>
                </div>

                <div class="py-5">

                    <table class="mb-5 min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr>
                                <th class="sm:px-6 sm:py-3 py-1 border-b border-color text-left text-xs leading-4 font-bold uppercase tracking-wider">
                                    {{ $t('fields.quantity') }}
                                </th>
                                <th class="sm:px-6 sm:py-3 py-1 border-b border-color text-left text-xs leading-4 font-bold uppercase tracking-wider">
                                    {{ $t('fields.article') }}
                                </th>
                                <th class="sm:px-6 sm:py-3 py-1 border-b border-color text-right text-xs leading-4 font-bold uppercase tracking-wider">
                                    {{ $t('fields.subtotal') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200">
                            <tr v-for="(line, index) in transaction.relationships.lines" v-bind:key="index">
                                <td class="sm:px-6 sm:py-2 py-1 align-top whitespace-no-wrap border-b border-color text-sm leading-5 font-medium">
                                    {{ Math.round(line.attributes.quantity) }}
                                </td>
                                <td class="sm:px-6 sm:py-2 py-1 align-top whitespace-no-wrap border-b border-color text-sm leading-5">
                                    <div :class="{'pl-3 italic': line.attributes.addon == true}">
                                        {{ line.attributes.description }}
                                    </div>
                                    <div class="pl-3 italic"
                                         v-for="(value, description) in line.attributes.kitchen_groceries" :key="value">
                                        {{ description }}: {{ value }}
                                    </div>
                                </td>
                                <td class="sm:px-6 sm:py-2 py-1 align-top whitespace-no-wrap border-b border-color text-sm leading-5 text-right">
                                    <!-- <span v-if="line.attributes.total != 0"> -->
                                        {{ $n(line.attributes.total, currency) }}
                                    <!-- </span> -->
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="2"
                                    class="sm:px-6 sm:py-2 py-1 whitespace-no-wrap border-t-2 border-color text-sm font-bold leading-5 font-medium text-main text-right">
                                    {{ $t('fields.total') }}
                                </td>
                                <td class="sm:px-6 sm:py-2 py-1 whitespace-no-wrap border-t-2 border-color text-sm text-right font-bold leading-5 font-medium text-right">
                                    {{ $n(transaction.attributes.total, currency) }}
                                </td>
                            </tr>
                            <tr v-for="(taxes, index) in transaction.relationships.taxes" v-bind:key="index">
                                <td v-if="parseInt(index) > 0" class="sm:px-6 whitespace-no-wrap text-xs leading-5 font-medium text-main text-right"
                                    colspan="2">{{ index }} belasting:
                                </td>
                                <td v-if="parseInt(index) > 0" class="sm:px-6 whitespace-no-wrap text-xs leading-5 font-medium text-main text-right">
                                    {{ $n(taxes, currency) }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                    <table class="min-w-full divide-y divide-gray-200" v-if="transaction.relationships.payments.length > 0">
                        <thead>
                            <tr>
                                <th class="sm:px-6 sm:py-3 py-1 border-b border-color text-left text-xs leading-4 font-bold uppercase tracking-wider">
                                    {{ $t('fields.paymenttype') }}
                                </th>
                                <th class="sm:px-6 sm:py-3 py-1 border-b border-color text-right text-xs leading-4 font-bold uppercase tracking-wider">
                                    {{ $t('fields.amount') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200">
                            <tr v-for="(payment, index) in transaction.relationships.payments" v-bind:key="index">
                                <td class="sm:px-6 sm:py-2 py-1 align-top whitespace-no-wrap border-b border-color text-sm leading-5 font-medium">
                                    {{ payment.attributes.paymenttype }}
                                </td>
                                <td class="sm:px-6 sm:py-2 py-1 align-top whitespace-no-wrap border-b border-color text-sm leading-5 text-right">
                                    {{ $n(payment.attributes.amount, currency) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        </div>
    </div>
        <!-- @if(isset($order))
        <div class="mt-15 flex justify-center">
            @if(in_array($order->method, ['delivery', 'takeaway']))
            <a href="{{ route('order.menu', $transaction->location->slug) }}" class="py-2 px-4 border border-transparent bg-button text-button text-sm font-medium rounded-sm text-button bg-button focus:outline-none  transition duration-150 ease-in-out">
                {{ __('Place another order') }}
            </a>
            @elseif(in_array($order->method, ['quickorder']))
            <a href="{{ route('quickorder.menu', $transaction->location->slug) }}" class="py-2 px-4 border border-transparent bg-button text-button text-sm font-medium rounded-sm text-button bg-button focus:outline-none  transition duration-150 ease-in-out">
                {{ __('Place another order') }}
            </a>
            @endif
        </div>
        @endif -->
    </div>
</template>

<script>
// import axios from 'axios';
import * as _api from '../lib/api';
import Ticket from '../components/Ticket.vue';
import VueQrcode from 'vue3-qrcode';
import * as _state from "../lib/state";

export default {
    props: ['transaction_id'],
    name: 'UserTransaction',
    components: {
        Ticket,
        VueQrcode,
    },
    data() {
        return {
            transaction: null,
        }
    },
    async mounted() {
        this.transaction = await _api.get('customer/transactions/:id', this.transaction_id, false);
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
        currency (){
            return this.config.settings.currency;
        }
    }

}
</script>
